import { Modal } from "components/components-common/modal";
import {
    StyledClose,
    StyledInner,
} from "components/components-common/modal/styled";
import styled from "styled-components";
import { media } from "../../../../../services/styled-components/variables";

export const Img = styled.img`
    width: 100%;
    display: block;
`;

export const ImgMobile = styled(Img)`
    ${media.sm`
        display: none;
    `}
`;

export const ImgTablet = styled(Img)`
    display: none;

    ${media.sm`
        display: block;
    `}

    ${media.md`
        display: none;
    `}
`;

export const ImgDesktop = styled(Img)`
    display: none;

    ${media.md`
        display: block;
    `}
`;

export const LinkBanner = styled.a`
    display: contents;
`;

export const BannerModal = styled(Modal)`
    ${StyledInner} {
        padding: 0;
    }
`;
export const ConfirmModal = styled(Modal)`
    ${StyledInner} {
        padding: 1.5rem;
        max-width: 100%;
        background-color: white;
        width: auto;
    }
    ${StyledClose} {
        top: 0;
        right: 0;
        width: 2rem;
        height: 2rem;
        background-color: transparent;
    }
`;

export const CustomModal = styled(Modal)`
    ${StyledInner} {
        padding: 0;
        background-color: transparent;
    }
`;

export const Link = styled.a`
    &:hover {
        opacity: 0.95;
    }
`;
