import { css } from "styled-components";
const fontsPath = "../assets/fonts/roboto-flex/";

export const RobotoFlexFonts = css`
    @font-face {
        font-family: "Roboto Flex";
        src: url("${fontsPath}RobotoFlex-Regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Flex";
        src: url("${fontsPath}RobotoFlex-Medium.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Flex";
        src: url("${fontsPath}RobotoFlex-SemiBold.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Flex";
        src: url("${fontsPath}RobotoFlex-Bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Flex";
        src: url("${fontsPath}RobotoFlex-ExtraBold.woff2") format("woff2");
        font-weight: 800;
        font-style: normal;
    }
`;
