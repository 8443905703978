import { Img, ImgDesktop, ImgMobile, ImgTablet, LinkBanner } from "./styled";
import { INotificationContentProps } from "..";
import { BannerPopupData, BannerDataSrc } from "./banner-popup-data";

interface IProps extends INotificationContentProps {
    data: BannerPopupData<BannerDataSrc>;
}

export const BannerContent = (props: IProps) => {
    const bannerData = props.data.data;
    const bannerSrc = bannerData.src;

    if (typeof bannerSrc === "string") {
        return (
            <LinkBanner href={bannerData.link} target="__blank">
                <Img src={bannerSrc} loading="lazy" />
            </LinkBanner>
        );
    }

    return (
        <LinkBanner href={bannerData.link} target="__blank">
            <ImgMobile src={bannerSrc?.mobile} loading="lazy" />
            <ImgTablet src={bannerSrc?.tablet} loading="lazy" />
            <ImgDesktop src={bannerSrc?.desktop} loading="lazy" />
        </LinkBanner>
    );
};
