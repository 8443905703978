import { useMessengers } from "hooks/query/use-messengers";
import { useTranslationList } from "hooks/use-translations-list";
import { useTranslation } from "react-i18next";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "./use-context-exist";
import { _1XBET_PARTNERS } from "server/partner-programs";

export function useMessengersInForms(showDisabled?: boolean) {
    const [__] = useTranslation();
    const { messengers, loading } = useMessengers();
    const { INVALID_FORMAT } = useTranslationList();
    const messengerOptions = (messengers || [])
        .filter(messenger => (!showDisabled ? messenger.isEnabled : true))
        .map(messenger => ({
            value: messenger,
            label: messenger.name,
        }));

    const defaultPlaceholder = __("Логин мессенджера");

    const { partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const getCustomTelegramPlaceholder = () => {
        const customPlaceholders = {
            [_1XBET_PARTNERS]: "@" + __("Имя пользователя"),
        };

        return (
            customPlaceholders[
                partnersProgramId as keyof typeof customPlaceholders
            ] || __("Имя пользователя")
        );
    };

    const getLabel = (messengerId: number | undefined) => {
        switch (messengerId) {
            // skype
            case 2:
                return __("Имя пользователя или номер телефона");
            // whatsapp
            case 3:
                return __("Номер телефона");
            // telegram
            case 4:
                return getCustomTelegramPlaceholder();
            // facebook
            case 5:
                return __("Имя пользователя");
            // twitter
            case 6:
                return "@" + __("Имя пользователя");
            case 17:
                return "LINE ID";
            case 19:
                return "WeChat ID";
            // viber
            case 21:
                return __("Номер телефона");
            case 23:
                return "QQ ID";
            case 25:
                return __("Личная страница в LinkedIn");
            default:
                return defaultPlaceholder;
        }
    };

    const getPlaceholder = (messengerId: number | undefined) =>
        messengers?.find(ms => ms.id === messengerId)?.placeholder ||
        defaultPlaceholder;

    const getMessengerLoginValidation = (
        messengerId: number | undefined,
        messengerLogin: string,
        required = true,
    ) => {
        if (!messengerId) {
            return;
        }
        const messenger =
            messengers && messengers.find(ms => ms.id === messengerId);

        if (!messenger) {
            return INVALID_FORMAT();
        }
        const regex = new RegExp(
            required
                ? messenger.validationTemplate
                : `^$|${messenger.validationTemplate}`,
        );
        const result = regex.test(messengerLogin);
        if (!result) {
            return INVALID_FORMAT();
        }
    };

    return {
        messengerOptions,
        getMessengerLoginValidation,
        getLabel,
        getPlaceholder,
        loading,
    };
}
