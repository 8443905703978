export const DEFAULT_PROGRAM = "999";
export const PARTNERS_1XSLOT = "12_1";
export const _1XSLOT_PARTNERS = "12_2";
export const _1XBIT_AFFILATES = "19_1";
export const _1XBIT_PARTNERS = "19_2";
export const BETANDYOU_PARTNERS = "23_1";
export const _1XBET_PARTNERS = "1_1";
export const PARTNERS_1XBET = "1_2";
export const _22BET_PARTNERS = "15_1";
export const PARTNERS_22BET = "15_2";
export const MSC_AFFILIATE_PARTNERS = "44_1";
export const _888STARZ_PARTNERS = "38_1";
export const AFFILIATES_1XSTAVKA = "7_1";
export const PARTNERS_1XSTAVKA = "7_2";
export const FAN_SPORT_PRO = "10_1";
export const _1XGAMES_PARTNERS = "14_1";
export const PARTNERS_1XGAMES = "14_2";
export const LINEBET_PARTNERS = "33_1";
export const MEGAPARI_PARTNERS = "36_1";
export const PARIPESA_PARTNERS = "41_1";
export const HELABET_PARTNERS = "39_1";
export const GOOOBET_AFFILIATES = "45_1";
export const JVSPIN_PARTNERS = "40_1";
export const BETFOOT_PARTNERS = "34_1";
export const BETVARZESH_AFFILATE = "35_1";
export const SAPPHIREBET_PARTNERS = "30_1";
export const XPARI_PARTNERS = "46_1";
export const IBETIN_AFFILATES = "47_1";
export const AZNBET_PARTNERS = "24_1";
export const ASTEKBET_PARTNERS = "9_1";
export const DOUBLEBET_PARTNERS = "21_1";
export const PARTNERS_MELBETRU = "20_1";
export const DEMO_PARTNERS = "2_1";
export const MELBET_PARTNERS = "4_1";
export const MELBET_AFFILIATES = "4_2";
export const TEST_PARTNERS = "3_1";
export const MELBET_AFF_PARTNERS = "42_1";
export const OPPABET_PARTNERS = "22_1";
export const VIVATBET_PARTNERS = "52_1";
export const MELBETMX_PARTNERS = "51_1";
export const BETANDYOU_AFFILIATES = "53_1";
export const BETANDYOU_PARTNERS_2 = "53_2";
export const DOINGSBET_AFFILIATES = "54_1";
export const COINPLAY_PARTNERS = "56_1";
export const BIZBET_PARTNERS = "57_1";
export const BETROLLER_PARTNERS = "58_1";
export const FSBET_PARTNERS = "59_1";
export const _1XCASINO_PARTNERS = "62_1";
export const PULLBET_AFFILIATES = "61_1";
export const WINWIN_PARTNERS = "65_1";
export const PARIPULSE_PARTNERS = "64_1";
export const BITON_AFFILIATES = "63_1";
export const ONJABET_PARTNERS = "66_1";
export const AFROPARI_PARTNERS = "67_1";
export const BEATBET_PARTNERS = "68_1";
export const _1XBET_PARTNERS_IRELAND = "69_1";
export const BETMOON_AFFILIATES = "71_1";
export const _10SBET_PARTNERS = "73_1";
export const RIZO_BET = "70_1";
export const PRO1BET_AFFILIATES = "72_1";
export const FUNPARI_PARTNERS = "75_1";
export const CLICKPARI_PARTNERS = "79_1";
export const ROLSBET_PARTNERS = "77_1";
export const GOLDPARI_PARTNERS = "76_1";
export const FORSAGEBET_PRO = "74_1";
export const VIPPARI_PARTNERS = "78_1";
export const PONGBET88_PARTNERS = "86_1";
export const _1XPARTNERS_SPAIN = "80_1";
export const JEETOPAK_PARTNERS = "82_1";
export const MYSTI_PARTNER = "83_1";
export const FAIRPARI_PARTNERS = "84_1";
export const PORTEBET_PARTNERS = "85_1";
export const NAJAHBET_PARTNERS = "87_1";
export const BETMAAN_PARTNERS = "88_1";
export const YOHOHO_PARTNERS = "89_1";
export const FASTPARI_PARTNERS = "90_1";
export const WINEBET_PARTNERS = "91_1";
export const BETJAM_PARTNERS = "92_1";
export const APUESTA360_PARTNERS = "93_1";
export const PLAFFILIATES = "95_1";
export const YESMOREBET_AFFILIATES = "96_1";
export const _1XBET_PARTNERS_SERBIA = "94_1";
export const MARAFON_PARTNERS = "97_1";
export const ULTRAPARI_PARTNERS = "98_1";
export const PARIPULSE_PARTNERS_NG = "99_1";
export const LILBET_PARTNERS = "101_1";
export const PARTNERS_WEPARI = "103_1";
export const BETONGAME_PARTNERS = "100_1";
export const PARTNERS_4CLOVER = "102_1";
export const PLANBET_PARTNERS = "105_1";
export const PARTNERS_VIVATBET_RS = "104_1";
export const BETMARKET_PARTNERS = "106_1";
export const VIVATBET_PARTNER = "107_1";
export const WOWBET_PARTNERS = "108_1";
export const PARTNERS_1XBET_PE = "109_1";
export const _1XBET_PARTNERS_BRAZIL = "110_1";
export const DERBYBET_PARTNERS = "111_1";
export const BETPAY_PARTNERS = "112_1";
export const GREENBET_PARTNERS = "113_1";
