import { Title, Paragraph } from "services/styled-components/typogoraphy";
import { Row, FormDirectionRow, FormGroupButton } from "styled/layouts";
import { useTranslation } from "react-i18next";
import { ButtonColored } from "styled/button";
import { ModalSize } from "components/components-common/modal/modal-size";
import { useModalState } from "hooks/use-modal-state";
import { ResetPasswordWithModal } from "./reset-password-with-modal";
import { useSignInLikeAPartner } from "hooks/sign-in/login/use-sign-in-like-a-partner";
import { useLocalizedAbsolutePush } from "hooks/use-localised-absolute-push";
import { Loader } from "components/components-common/loaders/cub";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { useQuery, useMutation } from "@apollo/client";
import {
    Partner,
    PartnerVariables,
    Reset2FA,
    Reset2FAVariables,
} from "gql/types/operation-result-types";
import GET_PARTNER from "../gql/get-partner.gql";
import RESET_2FA from "./reset2Fa.gql";
import { errorHandler } from "services/error-handler";
import { toast } from "react-toastify";
import { getPartnerStatus } from "services/project-utils";

export const EditPartnerCommon = ({ id }: { id: number }) => {
    const [__] = useTranslation();
    const { signInLikeAPartner, loading } = useSignInLikeAPartner();
    const push = useLocalizedAbsolutePush();
    const { stylesTheme } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const { modalProps, open, close } = useModalState({
        size: ModalSize.Large,
    });

    const { data } = useQuery<Partner, PartnerVariables>(GET_PARTNER, {
        variables: {
            id,
        },
    });

    const [mutation, { loading: loadingReset }] = useMutation<
        Reset2FA,
        Reset2FAVariables
    >(RESET_2FA, {
        refetchQueries: [
            {
                query: GET_PARTNER,
                variables: { id },
            },
        ],
    });

    const partner = data?.authorized.manager.main.partner;

    const onClickReset2FA = async () => {
        try {
            const result = await mutation({
                variables: {
                    partnerId: id,
                },
            });
            if (result.data?.authorized.manager.main.reset2FA) {
                toast.success(__("2FA сброшено"));
            } else {
                toast.error(__("Ошибка при сбросе 2FA"));
            }
        } catch (ex: any) {
            void errorHandler(ex);
        }
    };

    if (loading) {
        return (
            <Loader
                cubesColor={stylesTheme.accentColor}
                bgColor={stylesTheme.backgroundColor}
            />
        );
    }

    const nameAndStatus = partner
        ? `${partner.name}; ${getPartnerStatus(__, partner.status)}`
        : "";

    return (
        <>
            <Row>
                <FormDirectionRow>
                    <Title>{`id: ${id}; ${nameAndStatus}`}</Title>
                </FormDirectionRow>
                <FormDirectionRow>
                    <Paragraph>
                        {format(__("Google Authenticator включен: {result}"), {
                            result: partner?.is2FAEnabled
                                ? __("Да")
                                : __("Нет"),
                        })}
                    </Paragraph>
                    <FormGroupButton>
                        <ButtonColored
                            disabled={!partner?.is2FAEnabled || loadingReset}
                            onClick={onClickReset2FA}
                        >
                            {__("Отключить 2FA")}
                        </ButtonColored>
                    </FormGroupButton>
                </FormDirectionRow>
                <FormDirectionRow>
                    <FormGroupButton>
                        <ButtonColored onClick={open}>
                            {__("Сбросить пароль")}
                        </ButtonColored>
                    </FormGroupButton>
                    <FormGroupButton>
                        <ButtonColored
                            onClick={async () => {
                                await signInLikeAPartner(id);
                                push("/partner");
                            }}
                        >
                            {__("Зайти в партнерский аккаунт")}
                        </ButtonColored>
                    </FormGroupButton>
                </FormDirectionRow>
            </Row>
            <ResetPasswordWithModal
                modalProps={modalProps}
                close={close}
                partnerId={Number(id)}
            />
        </>
    );
};
