/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloClient } from "@apollo/client";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { statesSelectors } from "services/redux/root/states/selectors";
import loadable from "@loadable/component";
import { GeneralInfromationContextProvider } from "components/general-information-context-provider";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { useUser } from "hooks/query/use-user";
import { Loader } from "components/components-common/loaders/cub";
import {
    _1XBIT_AFFILATES,
    _1XBIT_PARTNERS,
    _22BET_PARTNERS,
    PARTNERS_22BET,
    _888STARZ_PARTNERS,
    LINEBET_PARTNERS,
    MEGAPARI_PARTNERS,
    HELABET_PARTNERS,
    PARIPESA_PARTNERS,
    VIVATBET_PARTNERS,
    BIZBET_PARTNERS,
    PARTNERS_1XBET,
    DOUBLEBET_PARTNERS,
    PARIPULSE_PARTNERS,
    BETANDYOU_PARTNERS,
    WINWIN_PARTNERS,
    MELBET_PARTNERS,
    MYSTI_PARTNER,
    PORTEBET_PARTNERS,
    FAIRPARI_PARTNERS,
    MELBET_AFFILIATES,
    ULTRAPARI_PARTNERS,
    JVSPIN_PARTNERS,
    PARIPULSE_PARTNERS_NG,
    XPARI_PARTNERS,
    _1XSLOT_PARTNERS,
    BETJAM_PARTNERS,
    _1XBET_PARTNERS_SERBIA,
    WINEBET_PARTNERS,
    ROLSBET_PARTNERS,
    PONGBET88_PARTNERS,
    PARTNERS_VIVATBET_RS,
    CLICKPARI_PARTNERS,
    ONJABET_PARTNERS,
    VIVATBET_PARTNER,
    _1XBET_PARTNERS,
    PARTNERS_1XBET_PE,
    _1XBET_PARTNERS_BRAZIL,
} from "../../../server/partner-programs";

import { usePartnersProgram } from "hooks/use-partners-program-id";

interface IInjectedProps extends RouteComponentProps {
    isMenuBurgerClickedOnLanding: boolean;
}

interface IProps extends IInjectedProps {
    client: ApolloClient<unknown>;
}

const Landing1xbit = loadable(
    () => import("views/irregular-landings/19/views/landing"),
    {
        resolveComponent: components => components.Landing19,
    },
);
const Landing22bet = loadable(
    () => import("views/irregular-landings/15/views/landing"),
    {
        resolveComponent: components => components.Landing15,
    },
);
const Landing888starz = loadable(
    () => import("views/irregular-landings/38/views/landing"),
    {
        resolveComponent: components => components.Landing38,
    },
);
const LandingLinebet = loadable(
    () => import("views/irregular-landings/33/views/landing"),
    {
        resolveComponent: components => components.Landing33,
    },
);

const LandingMegapariNew = loadable(
    () => import("views/irregular-landings/36_new/views/landing"),
    {
        resolveComponent: components => components.Landing36New,
    },
);

const LandingHelabet = loadable(
    () => import("views/irregular-landings/39/views/landing"),
    {
        resolveComponent: components => components.Landing39,
    },
);
const LandingParipesa = loadable(
    () => import("views/irregular-landings/41/views/landing"),
    {
        resolveComponent: components => components.Landing41,
    },
);
const LandingParipesaNew = loadable(
    () => import("views/irregular-landings/41_new/views/landing"),
    {
        resolveComponent: components => components.Landing41New,
    },
);
const LandingVivatbet = loadable(
    () => import("views/irregular-landings/52/views/landing"),
    {
        resolveComponent: components => components.Landing52,
    },
);
const LandingBizbet = loadable(
    () => import("views/irregular-landings/57/views/landing"),
    {
        resolveComponent: components => components.Landing57,
    },
);
const Landing1xbet = loadable(
    () => import("views/irregular-landings/1_2/views/landing"),
    {
        resolveComponent: components => components.Landing1_2,
    },
);

const Landing1xbetFirstGroup = loadable(
    () => import("views/irregular-landings/1_1/views/landing"),
    {
        resolveComponent: components => components.Landing1_1,
    },
);

const LandingDoublebet = loadable(
    () => import("views/irregular-landings/21/views/landing"),
    {
        resolveComponent: components => components.Landing21,
    },
);

const LandingPariPulse = loadable(
    () => import("views/irregular-landings/64/views/landing"),
    {
        resolveComponent: components => components.Landing64,
    },
);

const LandingBetAndYou = loadable(
    () => import("views/irregular-landings/23/views/landing"),
    {
        resolveComponent: components => components.Landing23,
    },
);

const LandingWinWinBet = loadable(
    () => import("views/irregular-landings/65/views/landing"),
    {
        resolveComponent: components => components.Landing65,
    },
);

const LandingMelBet = loadable(
    () => import("views/irregular-landings/4_1/views/landing"),
    {
        resolveComponent: components => components.Landing4,
    },
);

const LandingMysti = loadable(
    () => import("views/irregular-landings/83/views/landing"),
    {
        resolveComponent: components => components.Landing83,
    },
);

const LandingPorteBet = loadable(
    () => import("views/irregular-landings/85/views/landing"),
    {
        resolveComponent: components => components.Landing85,
    },
);

const LandingFairPari = loadable(
    () => import("views/irregular-landings/84/views/landing"),
    {
        resolveComponent: components => components.Landing84,
    },
);

const LandingMelBetAffiliates = loadable(
    () => import("views/irregular-landings/4_2/views/landing"),
    {
        resolveComponent: components => components.Landing4_2,
    },
);

const JvspinPartners = loadable(
    () => import("views/irregular-landings/40/views/landing"),
    {
        resolveComponent: components => components.Landing40,
    },
);

const LandingUltrapari = loadable(
    () => import("views/irregular-landings/98/views/landing"),
    {
        resolveComponent: components => components.Landing98,
    },
);

const LandingPariPulseNG = loadable(
    () => import("views/irregular-landings/99/views/landing"),
    {
        resolveComponent: components => components.Landing99,
    },
);

const LandingXPariBet = loadable(
    () => import("views/irregular-landings/46/views/landing"),
    {
        resolveComponent: components => components.Landing46,
    },
);

const Landing1XSlot = loadable(
    () => import("../irregular-landings/12_2/views/landing"),
    {
        resolveComponent: components => components.Landing12_2,
    },
);

const LandingBetJam = loadable(
    () => import("views/irregular-landings/92/views/landing"),
    {
        resolveComponent: components => components.Landing92,
    },
);

const Landing1xbetSerbia = loadable(
    () => import("../irregular-landings/94/views/landing"),
    {
        resolveComponent: components => components.Landing94,
    },
);

const LandingWineBetPartners = loadable(
    () => import("../irregular-landings/91/views/landing"),
    {
        resolveComponent: components => components.Landing91,
    },
);

const LandingRolsBetPartners = loadable(
    () => import("../irregular-landings/77/views/landing"),
    {
        resolveComponent: components => components.Landing77,
    },
);

const LandingPongBet88Partners = loadable(
    () => import("../irregular-landings/86/views/landing"),
    {
        resolveComponent: components => components.Landing86,
    },
);

const LandingClickPariPartners = loadable(
    () => import("../irregular-landings/79/views/landing"),
    {
        resolveComponent: components => components.Landing79,
    },
);

const LandingOnjabetPartners = loadable(
    () => import("../irregular-landings/66/views/landing"),
    {
        resolveComponent: components => components.Landing66,
    },
);

const Landing1xbitNew = loadable(
    () => import("views/irregular-landings/19_new/views/landing"),
    {
        resolveComponent: components => components.Landing19New,
    },
);

const Landing1xbetPeru = loadable(
    () => import("views/irregular-landings/109/views/landing"),
    {
        resolveComponent: components => components.Landing109,
    },
);

const Landing1xbetBrasil = loadable(
    () => import("views/irregular-landings/110/views/landing"),
    {
        resolveComponent: components => components.Landing110,
    },
);

const Landing888starzNew = loadable(
    () => import("views/irregular-landings/38_new/views/landing"),
    {
        resolveComponent: components => components.Landing38New,
    },
);

const EmptyTemplate = loadable(
    () =>
        import(
            "views/irregular-landings/templates/empty-template/views/landing/"
        ),
    {
        resolveComponent: components => components.EmptyTemplate,
    },
);

const RegularLanding = loadable(() => import("views/landing/regular-landing"), {
    resolveComponent: components => components.Landing,
});

const RegularLoader = () => {
    const { stylesTheme } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    return (
        <Loader
            cubesColor={stylesTheme.accentColor}
            bgColor={stylesTheme.backgroundColor}
        />
    );
};

export const Landing = connect(state => ({
    isMenuBurgerClickedOnLanding:
        statesSelectors.getIsMenuBurgerClickedOnLanding(state),
}))(
    React.memo((props: IProps) => {
        const { url } = props.match;
        const partnersProgram = usePartnersProgram();
        const { loading } = useUser();
        if (!partnersProgram) {
            return null;
        }
        const { programId: partnersProgramId, showLanding } = partnersProgram;
        if (loading) {
            return (
                <GeneralInfromationContextProvider>
                    <RegularLoader />
                </GeneralInfromationContextProvider>
            );
        }

        if (!showLanding) {
            return <EmptyTemplate url={url} />;
        }

        if (
            partnersProgramId === FAIRPARI_PARTNERS &&
            // TODO: Когда уберется условие переместить в Map
            !__ENVIRONMENT__.production
        ) {
            return <LandingFairPari url={url} />;
        }

        if (
            partnersProgramId === MYSTI_PARTNER &&
            // TODO: Когда уберется условие переместить в Map
            !__ENVIRONMENT__.production
        ) {
            return <LandingMysti url={url} />;
        }

        if (
            partnersProgramId === PARIPESA_PARTNERS &&
            // TODO: Когда уберется условие переместить в Map
            !__ENVIRONMENT__.production
        ) {
            return <LandingParipesaNew url={url} />;
        }

        if (
            partnersProgramId === ONJABET_PARTNERS &&
            // TODO: Когда уберется условие переместить в Map
            !__ENVIRONMENT__.production
        ) {
            return <LandingOnjabetPartners url={url} />;
        }

        if (
            partnersProgramId === _1XBIT_PARTNERS &&
            // TODO: Когда уберется условие переместить в Map
            !__ENVIRONMENT__.production
        ) {
            return <Landing1xbitNew url={url} />;
        }
        if (
            partnersProgramId === _1XBET_PARTNERS &&
            // TODO: Когда уберется условие переместить в Map
            !__ENVIRONMENT__.production
        ) {
            return <Landing1xbetFirstGroup url={url} />;
        }
        if (
            partnersProgramId === PARTNERS_1XBET_PE &&
            // TODO: Когда уберется условие переместить в Map
            !__ENVIRONMENT__.production
        ) {
            return <Landing1xbetPeru url={url} />;
        }
        if (
            partnersProgramId === _1XBET_PARTNERS_BRAZIL &&
            // TODO: Когда уберется условие переместить в Map
            !__ENVIRONMENT__.production
        ) {
            return <Landing1xbetBrasil url={url} />;
        }

        if (
            partnersProgramId === _888STARZ_PARTNERS &&
            // TODO: Когда уберется условие переместить в Map
            !__ENVIRONMENT__.production
        ) {
            return <Landing888starzNew url={url} />;
        }

        const partnersWithIrregularLanging = new Map([
            [_1XBIT_AFFILATES, Landing1xbit],
            [_1XBIT_PARTNERS, Landing1xbit],
            [_22BET_PARTNERS, Landing22bet],
            [PARTNERS_22BET, Landing22bet],
            [_888STARZ_PARTNERS, Landing888starz],
            [LINEBET_PARTNERS, LandingLinebet],
            [HELABET_PARTNERS, LandingHelabet],
            [PARIPESA_PARTNERS, LandingParipesa],
            [VIVATBET_PARTNERS, LandingVivatbet],
            [BIZBET_PARTNERS, LandingBizbet],
            [PARTNERS_1XBET, Landing1xbet],
            [DOUBLEBET_PARTNERS, LandingDoublebet],
            [PARIPULSE_PARTNERS, LandingPariPulse],
            [BETANDYOU_PARTNERS, LandingBetAndYou],
            [WINWIN_PARTNERS, LandingWinWinBet],
            [MELBET_PARTNERS, LandingMelBet],
            [PORTEBET_PARTNERS, LandingPorteBet],
            [PARIPULSE_PARTNERS_NG, LandingPariPulseNG],
            [JVSPIN_PARTNERS, JvspinPartners],
            [MELBET_AFFILIATES, LandingMelBetAffiliates],
            [ULTRAPARI_PARTNERS, LandingUltrapari],
            [_1XBET_PARTNERS_SERBIA, Landing1xbetSerbia],
            [PARTNERS_VIVATBET_RS, LandingVivatbet],
            [VIVATBET_PARTNER, LandingVivatbet],
            [_1XSLOT_PARTNERS, Landing1XSlot],
            [BETJAM_PARTNERS, LandingBetJam],
            [WINEBET_PARTNERS, LandingWineBetPartners],
            [PONGBET88_PARTNERS, LandingPongBet88Partners],
            [ROLSBET_PARTNERS, LandingRolsBetPartners],
            [XPARI_PARTNERS, LandingXPariBet],
            [MEGAPARI_PARTNERS, LandingMegapariNew],
            [CLICKPARI_PARTNERS, LandingClickPariPartners],
        ]);

        const IrregularLangingWrapper =
            partnersWithIrregularLanging.get(partnersProgramId);

        if (IrregularLangingWrapper) {
            return <IrregularLangingWrapper url={url} />;
        }

        return (
            <GeneralInfromationContextProvider>
                <RegularLanding url={url} search={props.location.search} />
            </GeneralInfromationContextProvider>
        );
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
