import { TFunction } from "i18next";
import {
    GetCountries_countries,
    PartnerAccount_authorized_partnerAccount,
} from "gql/types/operation-result-types";
import { PopupType } from "./popup-id";
import { ReferencePopupData } from "./block-reference/reference-popup-data";
import { ConfirmEmailPopupData } from "./confirm-email/confirm-email-popup-data";
import { InfoBlockPopupData } from "./info-block/info-block-popup-data";
import { TelegramPopupData } from "./telegram/telegram-popup-data";
import { BannerPopupData } from "./banner/banner-popup-data";
import { PartnerBanner as PartnerBanner38 } from "./custom-banners/38_1/partner-banner";
import bannerBangladeshSrc from "assets/images/banner-modal/bangladesh.jpg";
import bannerEgyptSrc from "assets/images/banner-modal/egypt.jpg";
import bannerAfropariSrc from "assets/images/banner-modal/afropari.jpg";
import bannerGoldpariSrc from "assets/images/banner-modal/goldpari.jpg";
import bannerFunpariSrc from "assets/images/banner-modal/funpari.jpg";
import _1xPartnersBDSrc from "assets/images/banner-modal/1x-partners-bd.jpg";
import _1xPartnersleagueUZSrc from "assets/images/banner-modal/league-competition/uz.jpg";
import melbetBannerSrc from "assets/images/banner-modal/melbet.png";
import melbetAfricaBannerSrc from "assets/images/banner-modal/melbet-africa.png";
import _1xPartnersUZSrc from "assets/images/banner-modal/1x-partners-uz.jpg";
import _1xPartnersIndiaSrc from "assets/images/banner-modal/1-1-india.png";
import _1xPartnersNewsSrc from "assets/images/banner-modal/1-1-big-news.png";
import _42mobileSrc from "assets/images/banner-modal/4_2/4-2-mobile.jpg";
import _42tabletSrc from "assets/images/banner-modal/4_2/4-2-tablet.jpg";
import _42desktopSrc from "assets/images/banner-modal/4_2/4-2-desktop.jpg";
import _62mobileSrc from "assets/images/banner-modal/62/62-mobile.jpg";
import _62tabletSrc from "assets/images/banner-modal/62/62-tablet.jpg";
import _62desktopSrc from "assets/images/banner-modal/62/62-desktop.jpg";
import {
    getTelegramSrcByCountryId,
    TelegramBanner,
} from "./custom-banners/1_1/telegram-banner";
import {
    ARABIA_COUNTIRES_1XBET,
    CountryId,
    LATAM_COUNTIRES_1XBET,
    getCountryCodesById,
} from "libs/country-id";
import { excludeCountryCodes } from "libs/exclude-country-codes";
import _109WelcomeOfferSpSrc from "assets/images/banner-modal/109/1/welcome_offer_sp.png";
import _109WelcomeOfferRuSrc from "assets/images/banner-modal/109/1/welcome_offer_ru.png";
import _109WelcomeOfferEnSrc from "assets/images/banner-modal/109/1/welcome_offer_en.png";
import LPAFFtournamentSrc from "assets/images/banner-modal/1_1/LP_AFF_tournament.png";
import _1xPartnerCompetitionNp from "assets/images/banner-modal/1_1/partner-competition-np.jpg";
import _1xPartnerCompetitionBd from "assets/images/banner-modal/1_1/partner-competition-bd.jpg";
import { PartnerBanner as PartnerBanner57 } from "./custom-banners/57_1/partner-banner";
import melbetApkSrc from "assets/images/banner-modal/4_2/apk.png";
import _1xcazinoSrc from "assets/images/banner-modal/1xcasino.png";

const get109WelcomeOfferBanner = (lang: string | null) => {
    switch (lang) {
        case "ru":
            return _109WelcomeOfferRuSrc;
        case "es":
            return _109WelcomeOfferSpSrc;
        default:
            return _109WelcomeOfferEnSrc;
    }
};

export const getPopupsConfig = (
    currentGeoCountryCode: string | undefined,
    partnerAccountData: PartnerAccount_authorized_partnerAccount["mainAccountData"],
    countryCodes: Map<GetCountries_countries["id"], string>,
    __: TFunction,
    lang: string | null,
) => {
    return [
        new ReferencePopupData({
            id: 11,
            type: PopupType.BlockReference,
            disallowedAdAgentIds: ["7", "20", "10", "48", "30", "33", "57"],
            disallowedAffiliateIds: ["134", "142", "7009"],
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "3",
                name: "RUB",
            },
            title: __("Текущие партнерские ссылки вновь были заблокированы!"),
            /* prettier-ignore */
            description: __("Во избежание потери трафика, просим вас заменить все маркетинговые материалы на новые, как можно скорее. Ваши партнерские ссылки уже обновлены и находятся в разделе \"Маркетинговые инструменты\"."),
            infoText: __(
                "Обратите внимание, что домен рабочей партнерской ссылки должен быть:",
            ),
        }),

        new TelegramPopupData({
            id: 12,
            type: PopupType.Telegram,
            linkInPopup: "https://t.me/joinchat/AAAAAERxS0y4a4CAOX5EMA",
            timesToShow: 3,
            adAgentId: "1",
            materialGroupId: "1",
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "6",
                name: "USD",
            },
        }),
        new TelegramPopupData({
            id: 13,
            type: PopupType.Telegram,
            linkInPopup: "https://t.me/joinchat/AAAAAEMTyudFqq3oQ80RnQ",
            timesToShow: 3,
            adAgentId: "1",
            materialGroupId: "1",
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "3",
                name: "RUB",
            },
        }),
        new ConfirmEmailPopupData({
            id: 14,
            type: PopupType.ConfirmEmail,
            adAgentId: ["52", "57", "72"],
            partnerAccountData,
            currentGeoCountryCode,
            canBeClosed: true,
        }),
        new BannerPopupData({
            id: 16,
            type: PopupType.Banner,
            src: bannerBangladeshSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Bangladesh,
            ]),
            currentGeoCountryCode,
        }),
        new BannerPopupData({
            id: 18,
            type: PopupType.Banner,
            src: bannerEgyptSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [CountryId.Egypt]),
            currentGeoCountryCode,
        }),
        new BannerPopupData({
            id: 19,
            type: PopupType.Banner,
            src: bannerAfropariSrc,
            adAgentId: "67",
            timesToShow: 1,
        }),
        new BannerPopupData({
            id: 20,
            type: PopupType.Banner,
            src: bannerGoldpariSrc,
            adAgentId: "76",
            timesToShow: 1,
        }),
        new BannerPopupData({
            id: 21,
            type: PopupType.Banner,
            src: bannerFunpariSrc,
            adAgentId: "75",
            timesToShow: 1,
        }),
        new ReferencePopupData({
            id: 26,
            type: PopupType.BlockReference,
            adAgentId: ["30", "33"],
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "6",
                name: "USD",
            },
            title: __("Текущие партнерские ссылки вновь были заблокированы!"),
            /* prettier-ignore */
            description: __("Во избежание потери трафика, просим вас заменить все маркетинговые материалы на новые, как можно скорее. Ваши партнерские ссылки уже обновлены и находятся в разделе \"Маркетинговые инструменты\"."),
            infoText: __(
                "Обратите внимание, что домен рабочей партнерской ссылки должен быть:",
            ),
        }),
        new InfoBlockPopupData({
            id: 27,
            type: PopupType.InfoBlock,
            adAgentId: "57",
            partnerAccountData,
        }),
        new BannerPopupData({
            id: 32,
            type: PopupType.CustomBanner,
            src: "",
            adAgentId: "38",
            materialGroupId: "1",
            currentGeoCountryCode,
            component: PartnerBanner38,
            showOnceADay: true,
        }),
        new ConfirmEmailPopupData({
            id: 33,
            type: PopupType.ConfirmEmail,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Uzbekistan,
                CountryId.India,
                CountryId.Bangladesh,
            ]),
            partnerAccountData,
            countryIds: [
                CountryId.Uzbekistan,
                CountryId.India,
                CountryId.Bangladesh,
            ],
            currentGeoCountryCode,
            canBeClosed: true,
        }),
        new ConfirmEmailPopupData({
            id: 35,
            type: PopupType.ConfirmEmail,
            adAgentId: "4",
            materialGroupId: "2",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Bangladesh,
                CountryId.Uruguay,
                CountryId.Bolivia,
                CountryId.Brazil,
                CountryId.Guatemala,
                CountryId.Honduras,
                CountryId.DominicanRepublic,
                CountryId.CostaRica,
                CountryId.Mexico,
                CountryId.Nicaragua,
                CountryId.Paraguay,
                CountryId.PuertoRico,
                CountryId.ElSalvador,
                CountryId.Ecuador,
            ]),
            partnerAccountData,
            countryIds: [
                CountryId.Bangladesh,
                CountryId.Uruguay,
                CountryId.Bolivia,
                CountryId.Brazil,
                CountryId.Guatemala,
                CountryId.Honduras,
                CountryId.DominicanRepublic,
                CountryId.CostaRica,
                CountryId.Mexico,
                CountryId.Nicaragua,
                CountryId.Paraguay,
                CountryId.PuertoRico,
                CountryId.ElSalvador,
                CountryId.Ecuador,
            ],
            currentGeoCountryCode,
            canBeClosed: false,
        }),
        new BannerPopupData({
            id: 36,
            type: PopupType.Banner,
            src: _1xPartnersBDSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Bangladesh,
            ]),
            currentGeoCountryCode,
            link: "https://casaff.top/L?tag=d_3603128m_72475c_&site=3603128&ad=72475",
            showOnceADay: true,
        }),
        new ConfirmEmailPopupData({
            id: 39,
            type: PopupType.ConfirmEmail,
            adAgentId: "4",
            materialGroupId: "1",
            partnerAccountData,
            currentGeoCountryCode,
            canBeClosed: true,
            countryCode: getCountryCodesById(
                countryCodes,
                excludeCountryCodes(countryCodes, [
                    CountryId.Cameroon,
                    CountryId.Brazil,
                    CountryId.Kazakhstan,
                ]),
            ),
            countryIds: excludeCountryCodes(countryCodes, [
                CountryId.Cameroon,
                CountryId.Brazil,
                CountryId.Kazakhstan,
            ]),
        }),
        new BannerPopupData({
            id: 53,
            type: PopupType.Banner,
            src: _1xPartnersleagueUZSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Uzbekistan,
            ]),
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 60,
            type: PopupType.CustomBanner,
            src:
                getTelegramSrcByCountryId(
                    countryCodes,
                    currentGeoCountryCode,
                ) || "",
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Malaysia,
                CountryId.Thailand,
                CountryId.Vietnam,
            ]),
            currentGeoCountryCode,
            component: TelegramBanner,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 61,
            type: PopupType.Banner,
            src: melbetBannerSrc,
            adAgentId: "4",
            countryCode: getCountryCodesById(countryCodes, [CountryId.Tunisia]),
            link: "https://t.me/Agents_Teamcash_Melbet",
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 67,
            type: PopupType.Banner,
            src: get109WelcomeOfferBanner(lang),
            adAgentId: "109",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [CountryId.Peru]),
            currentGeoCountryCode,
            timesToShow: 1,
        }),
        new BannerPopupData({
            id: 68,
            type: PopupType.Banner,
            src: LPAFFtournamentSrc,
            link: "https://1xregister.co/ipr2025_pop",
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [CountryId.India]),
            currentGeoCountryCode,
            timesToShow: 1,
        }),
        new ConfirmEmailPopupData({
            id: 69,
            type: PopupType.ConfirmEmail,
            adAgentId: "4",
            materialGroupId: "1",
            partnerAccountData,
            currentGeoCountryCode,
            canBeClosed: false,
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Cameroon,
                CountryId.Brazil,
                CountryId.Kazakhstan,
            ]),
            countryIds: [
                CountryId.Cameroon,
                CountryId.Brazil,
                CountryId.Kazakhstan,
            ],
            withMaskEmail: true,
        }),
        new BannerPopupData({
            id: 71,
            type: PopupType.Banner,
            src: melbetAfricaBannerSrc,
            adAgentId: "4",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Senegal,
                CountryId.BurkinaFaso,
                CountryId.CoteDIvoire,
                CountryId.CongoKinshasa,
                CountryId.Zambia,
                CountryId.Benin,
                CountryId.Cameroon,
                CountryId.Burundi,
                CountryId.Kenya,
                CountryId.Tanzania,
                CountryId.Togo,
                CountryId.Ethiopia,
                CountryId.CongoBrazzaville,
            ]),
            link: "https://melbetsafariafrica.com/",
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new ConfirmEmailPopupData({
            id: 72,
            type: PopupType.ConfirmEmail,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                ...LATAM_COUNTIRES_1XBET,
                ...ARABIA_COUNTIRES_1XBET,
            ]),
            partnerAccountData,
            countryIds: [...LATAM_COUNTIRES_1XBET, ...ARABIA_COUNTIRES_1XBET],
            currentGeoCountryCode,
            canBeClosed: false,
            withMaskEmail: true,
        }),
        new BannerPopupData({
            id: 73,
            type: PopupType.Banner,
            src: _1xPartnersUZSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Uzbekistan,
            ]),
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 74,
            type: PopupType.Banner,
            src: _1xPartnerCompetitionNp,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [CountryId.Nepal]),
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 75,
            type: PopupType.Banner,
            src: _1xPartnerCompetitionBd,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Bangladesh,
            ]),
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 76,
            type: PopupType.Banner,
            src: melbetApkSrc,
            adAgentId: "4",
            materialGroupId: "2",
            countryCode: getCountryCodesById(countryCodes, [
                ...countryCodes.keys(),
            ]),
            currentGeoCountryCode,
            link: "https://dkr84sogf1xdp.cloudfront.net/release/melbet/site/melbet.apk",
        }),

        new BannerPopupData({
            id: 77,
            type: PopupType.Banner,
            src: _1xcazinoSrc,
            adAgentId: "62",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Uzbekistan,
                CountryId.Kyrgyzstan,
                CountryId.Kazakhstan,
                CountryId.Slovakia,
                CountryId.Bangladesh,
            ]),
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 78,
            type: PopupType.CustomBanner,
            component: PartnerBanner57,
            adAgentId: "57",
            materialGroupId: "1",
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 79,
            type: PopupType.Banner,
            src: _1xPartnersNewsSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [CountryId.India]),
            currentGeoCountryCode,
            link: "https://1xregister.co/smm",
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 80,
            type: PopupType.Banner,
            src: _1xPartnersIndiaSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [CountryId.India]),
            currentGeoCountryCode,
            link: "https://1xregister.co/raffle2025",
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 81,
            type: PopupType.Banner,
            src: {
                mobile: _42mobileSrc,
                tablet: _42tabletSrc,
                desktop: _42desktopSrc,
            },
            adAgentId: "4",
            countryCode: getCountryCodesById(countryCodes, [CountryId.India]),
            showOnceADay: true,
            currentGeoCountryCode,
        }),
        new ConfirmEmailPopupData({
            id: 82,
            type: PopupType.ConfirmEmail,
            adAgentId: "4",
            materialGroupId: "2",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Russia,
                CountryId.India,
                CountryId.Argentina,
                CountryId.UnitedKingdom,
                CountryId.Morocco,
                CountryId.Peru,
                CountryId.Colombia,
                CountryId.Pakistan,
                CountryId.Somalia,
                CountryId.Venezuela,
                CountryId.Bermuda,
                CountryId.Afghanistan,
                CountryId.Chile,
                CountryId.Nigeria,
                CountryId.Philippines,
                CountryId.Uzbekistan,
            ]),
            partnerAccountData,
            countryIds: [
                CountryId.Russia,
                CountryId.India,
                CountryId.Argentina,
                CountryId.UnitedKingdom,
                CountryId.Morocco,
                CountryId.Peru,
                CountryId.Colombia,
                CountryId.Pakistan,
                CountryId.Somalia,
                CountryId.Venezuela,
                CountryId.Bermuda,
                CountryId.Afghanistan,
                CountryId.Chile,
                CountryId.Nigeria,
                CountryId.Philippines,
                CountryId.Uzbekistan,
            ],
            currentGeoCountryCode,
            canBeClosed: false,
            withMaskEmail: true,
        }),
        new BannerPopupData({
            id: 83,
            type: PopupType.Banner,
            src: {
                mobile: _62mobileSrc,
                tablet: _62tabletSrc,
                desktop: _62desktopSrc,
            },
            adAgentId: "62",
            countryCode: getCountryCodesById(countryCodes, [CountryId.Somalia]),
            currentGeoCountryCode,
            showOnceADay: true,
        }),
    ];
};
