import React from "react";
import { useDefinedContext } from "hooks/use-context-exist";
import { useLocation } from "react-router";
import { PartnersProgramGeneralInformationContext } from "views";
import { NoScriptBodyInjector } from "components/no-script-body-injector";
import { useTranslation } from "react-i18next";
import urljoin from "url-join";
import { languageListByPartner } from "services/i18n/language-list";
import { Helmet, HelmetsOpenedVisor } from "@webx/react-helmet-with-visor";
import {
    VIVATBET_PARTNERS,
    BIZBET_PARTNERS,
    PARIPESA_PARTNERS,
    PARTNERS_1XBET_PE,
} from "server/partner-programs";

interface IInjectedProps {}

interface IMetaImage {
    src: string;
    width: string;
    height: string;
}

interface IProps extends IInjectedProps {
    titleText?: string;
    metaText?: string;
    metaImage?: IMetaImage;
}

const getMetaImage = (metaImage: IMetaImage) => {
    const { width, height, src } = metaImage;

    return [
        <meta key="og:image" property="og:image" content={src} />,
        <meta key="og:image:width" property="og:image:width" content={width} />,
        <meta
            key="og:image:height"
            property="og:image:height"
            content={height}
        />,
        <meta key="twitter:card" name="twitter:card" content="summary" />,
        <meta key="twitter:image" name="twitter:image" content={src} />,
        <meta
            key="twitter:image:width"
            name="twitter:image:width"
            content={width}
        />,
        <meta
            key="twitter:image:height"
            name="twitter:image:height"
            content={height}
        />,
    ];
};

export const RootHelmetBodyInjector = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const pathname = useLocation().pathname;
    const {
        partnerProgramCompanyName,
        agentSiteInformation,
        landingMainPage,
        siteAddress,
        seoDomain,
        partnersProgramId,
    } = useDefinedContext(PartnersProgramGeneralInformationContext);
    const faviconPath = "/config-files/favicon";
    const titleText =
        props.titleText ||
        format(
            __("{PartnerCompanyName} | Партнерская программа {CompanyName}"),
            {
                PartnerCompanyName: partnerProgramCompanyName,
                CompanyName: agentSiteInformation.companyName,
            },
        );
    const metaText =
        props.metaText ||
        format(
            __(
                "Партнерская программа {PartnerCompanyName}. Зарабатывай на спорте вместе с {CompanyName} по реферальной программе до {Comission}.",
            ),
            {
                PartnerCompanyName: partnerProgramCompanyName,
                CompanyName: agentSiteInformation.companyName,
                Comission: landingMainPage?.comission,
            },
        );

    const getHreflangLinks = languageListByPartner(partnersProgramId)
        .getAll()
        .map(language => (
            <link
                key={language.code}
                rel="alternate"
                href={`${siteAddress}${
                    language.urlPrefix && `${language.urlPrefix}/`
                }`}
                hrefLang={language.code}
            />
        ));

    const robotsNoIndex = partnersProgramId === VIVATBET_PARTNERS;
    const canonicalAddress = seoDomain ? `https://${seoDomain}` : siteAddress;
    return (
        <>
            <Helmet defaultTitle={titleText}>
                <meta property="og:title" content={titleText} />
                <meta name="description" content={metaText} />
                <meta property="og:description" content={metaText} />
                {robotsNoIndex && <meta name="robots" content="noindex" />}
                {props.metaImage && getMetaImage(props.metaImage)}
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${faviconPath}/apple-touch-icon.png`}
                />
                <link
                    rel="canonical"
                    href={urljoin(canonicalAddress, pathname)}
                />
                <link rel="shortcut icon" href="/favicon.ico" />

                {(partnersProgramId === VIVATBET_PARTNERS ||
                    partnersProgramId === BIZBET_PARTNERS ||
                    partnersProgramId === PARIPESA_PARTNERS ||
                    partnersProgramId === PARTNERS_1XBET_PE) &&
                    getHreflangLinks}
            </Helmet>
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

export const RootHelmetAndScriptsBodyInjector = React.memo((props: IProps) => {
    const { seoScriptsAndTags } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <>
            <RootHelmetBodyInjector {...props} />
            <Helmet>
                <HelmetsOpenedVisor>
                    {seoScriptsAndTags.verificationTags}
                </HelmetsOpenedVisor>
                <HelmetsOpenedVisor>
                    {seoScriptsAndTags.trackingScripts}
                </HelmetsOpenedVisor>
            </Helmet>

            <NoScriptBodyInjector
                noscript={seoScriptsAndTags.trackingNoScripts}
            />
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
