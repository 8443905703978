import {
    PartnerAccount_authorized_partnerAccount,
    PartnerInformation,
} from "gql/types/operation-result-types";
import { INotificationPopupState } from "../../notification-popup";
import { PopupType } from "../popup-id";
import { PopupData } from "../popups-data";

const managerRoles: number[] = [7, 22, 24];

interface IConfirmEmailPopupDataProps {
    id: number;
    type: PopupType;
    adAgentId: string | string[];
    materialGroupId?: string;
    partnerAccountData: PartnerAccount_authorized_partnerAccount["mainAccountData"];
    countryCode?: string | string[];
    countryIds?: number[];
    currentGeoCountryCode?: string;
    canBeClosed: boolean;
    withMaskEmail?: boolean;
}

export class ConfirmEmailPopupData extends PopupData {
    constructor(public props: IConfirmEmailPopupDataProps) {
        super(props.id, props.type, false, null, null, null, props.canBeClosed);
    }

    public show(
        information: INotificationPopupState & PartnerInformation,
    ): boolean {
        const { user } = information.authorized;
        const {
            currentGeoCountryCode,
            partnerAccountData,
            adAgentId,
            materialGroupId,
            countryCode,
            countryIds,
        } = this.props;

        const isAdAgentMatch =
            user.adAgentId === adAgentId ||
            (Array.isArray(adAgentId) &&
                adAgentId.includes(user.adAgentId || ""));

        const isMaterialGroupMatch =
            !materialGroupId || user.materialGroupId === materialGroupId;

        const isCountryCodeMatch =
            !countryCode ||
            currentGeoCountryCode === countryCode ||
            (Array.isArray(countryCode) &&
                countryCode.includes(currentGeoCountryCode || "")) ||
            Boolean(
                countryIds &&
                    partnerAccountData?.countryId &&
                    countryIds.includes(partnerAccountData?.countryId),
            );

        const isManagerRole = user.roles?.some(role =>
            managerRoles.includes(Number(role)),
        );

        const isEmailConfirmed = partnerAccountData?.emailConfirmed;

        const shouldShowPopup =
            !isEmailConfirmed &&
            isAdAgentMatch &&
            isMaterialGroupMatch &&
            isCountryCodeMatch &&
            !isManagerRole;

        return shouldShowPopup;
    }
}
