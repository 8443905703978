import { PartnerInformation } from "gql/types/operation-result-types";
import { INotificationPopupState } from "..";
import { PopupType } from "../popup-id";
import { PopupData } from "../popups-data";
import { ICustomBannerWrapperProps } from "../custom-banners/custom-banner-wrapper";

export type BannerDataSrc =
    | string
    | { mobile: string; tablet: string; desktop: string };

interface IData<T extends BannerDataSrc = string> {
    adAgentId: string;
    src?: T;
    materialGroupId?: string;
    countryCode?: string | string[];
    currentGeoCountryCode?: string;
    timesToShow?: number;
    showOnceADay?: boolean;
    link?: string;
    component?: React.ComponentType<ICustomBannerWrapperProps>;
}

export class BannerPopupData<
    T extends BannerDataSrc = string,
> extends PopupData {
    data: IData<T>;

    constructor(
        data: IData<T> & {
            id: number;
            type: PopupType;
            showOnceADay?: boolean;
        },
    ) {
        super(data.id, data.type, data.showOnceADay);
        this.data = data;
    }

    public show(
        information: INotificationPopupState & PartnerInformation,
    ): boolean {
        const { user } = information.authorized;
        const {
            adAgentId,
            materialGroupId,
            countryCode,
            timesToShow,
            showOnceADay,
            currentGeoCountryCode,
        } = this.data;

        const isAdAgentMatch = user.adAgentId === adAgentId;
        const isMaterialGroupMatch =
            !materialGroupId || user.materialGroupId === materialGroupId;
        const isCountryCodeMatch =
            !countryCode ||
            currentGeoCountryCode === countryCode ||
            (Array.isArray(countryCode) &&
                countryCode.includes(currentGeoCountryCode || ""));
        const isTimesToShowMatch =
            !timesToShow || information.countOfTimesWasShown < timesToShow;
        const isShowOnceADayMatch =
            !showOnceADay || information.showPopupOnceDay;

        const shouldShowPopup =
            isAdAgentMatch &&
            isMaterialGroupMatch &&
            isCountryCodeMatch &&
            isTimesToShowMatch &&
            isShowOnceADayMatch;

        return shouldShowPopup;
    }
}
