export enum CountryId {
    Australia = 4,
    Austria = 5,
    Azerbaijan = 6,
    Albania = 7,
    Algeria = 8,
    Anguilla = 10,
    Angola = 9,
    Andorra = 11,
    AntiguaAndBarbuda = 12,
    NetherlandsAntilles = 13,
    Argentina = 14,
    Armenia = 15,
    Aruba = 16,
    Afghanistan = 17,
    TheBahamas = 18,
    Bangladesh = 19,
    Barbados = 20,
    Bahrain = 21,
    Belarus = 22,
    Belize = 23,
    Belgium = 24,
    Benin = 25,
    Bermuda = 26,
    Bulgaria = 27,
    Bolivia = 28,
    BosniaAndHerzegovina = 29,
    Botswana = 30,
    Brazil = 31,
    BritishVirginIslands = 32,
    Brunei = 33,
    BurkinaFaso = 34,
    Burundi = 35,
    Bhutan = 36,
    Vanuatu = 38,
    UnitedKingdom = 39,
    Hungary = 40,
    Venezuela = 41,
    EastTimor = 42,
    Vietnam = 43,
    Gabon = 44,
    Haiti = 45,
    Guyana = 46,
    Gambia = 47,
    Ghana = 48,
    Guadeloupe = 49,
    Guatemala = 50,
    GuineaBissau = 52,
    Guinea = 51,
    Germany = 53,
    Guernsey = 54,
    Gibraltar = 55,
    Honduras = 56,
    HongKong = 57,
    Grenada = 58,
    Greenland = 59,
    Greece = 60,
    Georgia = 61,
    Denmark = 62,
    Jersey = 63,
    Djibouti = 64,
    DominicanRepublic = 65,
    Egypt = 66,
    Zambia = 67,
    WesternSahara = 68,
    Zimbabwe = 69,
    Israel = 70,
    India = 71,
    Indonesia = 72,
    Jordan = 73,
    Iraq = 74,
    Iran = 75,
    Ireland = 76,
    Iceland = 77,
    Spain = 78,
    Italy = 79,
    Yemen = 80,
    CapeVerde = 81,
    Kazakhstan = 82,
    Cambodia = 83,
    Cameroon = 84,
    Canada = 85,
    Qatar = 86,
    Kenya = 87,
    Cyprus = 88,
    Kyrgyzstan = 228,
    Kiribati = 89,
    China = 90,
    Colombia = 91,
    Comoros = 92,
    CongoBrazzaville = 93,
    CongoKinshasa = 94,
    CostaRica = 95,
    CoteDIvoire = 96,
    Cuba = 97,
    Kuwait = 98,
    CookIslands = 99,
    Laos = 101,
    Latvia = 102,
    Lesotho = 103,
    Liberia = 104,
    Lebanon = 105,
    Libya = 106,
    Lithuania = 107,
    Liechtenstein = 108,
    Luxembourg = 109,
    Mauritius = 110,
    Mauritania = 111,
    Madagascar = 112,
    Malawi = 114,
    Malaysia = 115,
    Mali = 116,
    Maldives = 117,
    Malta = 118,
    Morocco = 125,
    Martinique = 119,
    Mexico = 120,
    Mozambique = 121,
    Moldova = 122,
    Monaco = 123,
    Mongolia = 124,
    MyanmarBurma = 126,
    IsleOfMan = 127,
    Namibia = 128,
    Nauru = 129,
    Nepal = 130,
    Niger = 131,
    Nigeria = 132,
    Netherlands = 133,
    Nicaragua = 134,
    NewZealand = 135,
    NewCaledonia = 136,
    Norway = 137,
    NorfolkIsland = 138,
    UnitedArabEmirates = 139,
    Oman = 140,
    SaintHelena = 161,
    Pakistan = 141,
    Panama = 142,
    PapuaNewGuinea = 143,
    Paraguay = 144,
    Peru = 145,
    PitcairnIslands = 146,
    Poland = 147,
    Portugal = 148,
    PuertoRico = 149,
    Reunion = 150,
    Russia = 1,
    Rwanda = 151,
    Romania = 152,
    ElSalvador = 154,
    Samoa = 155,
    SanMarino = 156,
    SaoTomeAndPrincipe = 157,
    SaudiArabia = 158,
    Swaziland = 159,
    NorthKorea = 162,
    NorthMacedonia = 113,
    Seychelles = 163,
    SaintPierreAndMiquelon = 164,
    Senegal = 165,
    SaintKittsAndNevis = 166,
    SaintVincentAndTheGrenadines = 167,
    SaintLucia = 160,
    Serbia = 168,
    Singapore = 169,
    Syria = 170,
    Slovakia = 171,
    Slovenia = 172,
    SolomonIslands = 173,
    Somalia = 174,
    Sudan = 175,
    Suriname = 176,
    SierraLeone = 177,
    Tajikistan = 178,
    Thailand = 180,
    Tahiti = 200,
    Taiwan = 179,
    Tanzania = 181,
    TurksAndCaicosIslands = 189,
    Togo = 182,
    Tokelau = 183,
    Tonga = 184,
    TrinidadAndTobago = 185,
    Tuvalu = 186,
    Tunisia = 187,
    Turkmenistan = 188,
    Turkey = 190,
    Uganda = 191,
    Uzbekistan = 192,
    Ukraine = 2,
    WallisAndFutuna = 37,
    Uruguay = 193,
    FaroeIslands = 194,
    Fiji = 195,
    Philippines = 196,
    Finland = 197,
    France = 198,
    FrenchGuiana = 199,
    Croatia = 201,
    CAR = 227,
    Chad = 202,
    Montenegro = 203,
    CzechRepublic = 204,
    Chile = 205,
    Switzerland = 206,
    Sweden = 207,
    SriLanka = 208,
    Ecuador = 209,
    EquatorialGuinea = 210,
    Eritrea = 211,
    Estonia = 212,
    Ethiopia = 213,
    SouthAfrica = 214,
    SouthKorea = 215,
    SouthOssetia = 216,
    Jamaica = 217,
    Japan = 218,
    Macau = 233,
    CaymanIslands = 136,
}

export const LATAM_COUNTIRES_MELBET = [
    CountryId.Uruguay,
    CountryId.Argentina,
    CountryId.Bolivia,
    CountryId.Brazil,
    CountryId.Venezuela,
    CountryId.Guatemala,
    CountryId.Honduras,
    CountryId.DominicanRepublic,
    CountryId.Colombia,
    CountryId.CostaRica,
    CountryId.Mexico,
    CountryId.Nicaragua,
    CountryId.Paraguay,
    CountryId.Peru,
    CountryId.PuertoRico,
    CountryId.ElSalvador,
    CountryId.Chile,
    CountryId.Ecuador,
] as const;

export const LATAM_COUNTIRES_1XBET = [
    CountryId.Uruguay,
    CountryId.Argentina,
    CountryId.Bolivia,
    CountryId.Brazil,
    CountryId.Venezuela,
    CountryId.Guatemala,
    CountryId.Honduras,
    CountryId.DominicanRepublic,
    CountryId.Colombia,
    CountryId.CostaRica,
    CountryId.Mexico,
    CountryId.Nicaragua,
    CountryId.Peru,
    CountryId.ElSalvador,
    CountryId.Chile,
    CountryId.Ecuador,
    CountryId.Panama,
    CountryId.TurksAndCaicosIslands,
    CountryId.BritishVirginIslands,
    CountryId.Haiti,
    CountryId.Grenada,
    CountryId.Anguilla,
    CountryId.Guadeloupe,
    CountryId.TheBahamas,
    CountryId.CaymanIslands,
] as const;

export const ARABIA_COUNTIRES_1XBET = [
    CountryId.Egypt,
    CountryId.Morocco,
    CountryId.Somalia,
    CountryId.Algeria,
    CountryId.Mauritania,
    CountryId.SaudiArabia,
] as const;

export const getCountryCodesById = (
    countryCodes: Map<CountryId, string>,
    countryIds: CountryId[],
) =>
    countryIds.reduce<string[]>((acc, id) => {
        const code = countryCodes.get(id);
        if (code) {
            acc.push(code);
        } else {
            // eslint-disable-next-line no-console
            console.error(`Не найден код для страны ID: ${id}`);
        }
        return acc;
    }, []);
