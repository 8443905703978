import bizbetDeskSrc from "assets/images/banner-modal/57/bizbetDesk.png";
import bizbetMobSrc from "assets/images/banner-modal/57/bizbetMob.png";
import { Link, Img } from "./styled";

export const PartnerBanner = () => {
    return (
        <Link
            href="https://dkr84sogf1xdp.cloudfront.net/release/bizbet/site/bizbet.apk"
            target="__blank"
        >
            <picture>
                <source media="(min-width: 1440px)" srcSet={bizbetDeskSrc} />
                <Img src={bizbetMobSrc} loading="lazy" />
            </picture>
        </Link>
    );
};
