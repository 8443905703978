import { LanguageList } from "@webx-react/i18n";
import { ILanguageOptions } from "@webx-react/i18n/lib/languages/language-list/language";
import {
    PARTNERS_22BET,
    _22BET_PARTNERS,
    BIZBET_PARTNERS,
    ONJABET_PARTNERS,
    AFROPARI_PARTNERS,
    _1XBET_PARTNERS_IRELAND,
    PARIPULSE_PARTNERS,
    VIVATBET_PARTNERS,
    GOLDPARI_PARTNERS,
    PORTEBET_PARTNERS,
    _1XPARTNERS_SPAIN,
    FAIRPARI_PARTNERS,
    _1XBET_PARTNERS_SERBIA,
    PARIPULSE_PARTNERS_NG,
    LILBET_PARTNERS,
    PARTNERS_VIVATBET_RS,
    VIVATBET_PARTNER,
    PARTNERS_1XBET_PE,
    _1XBET_PARTNERS_BRAZIL,
} from "server/partner-programs";

function getLanguageAndCountryCode(code: string) {
    const subtags = code.split("-");
    return {
        languageCode: subtags[0],
        countryCode: subtags[1],
    };
}

function convertLanguageList(
    intialLanguageList: Array<{
        code: string;
        nativeName: string;
        shortName: string;
        aliases?: string[];
    }>,
) {
    return intialLanguageList.map(intialLang => {
        const { countryCode } = getLanguageAndCountryCode(intialLang.code);
        const language: ILanguageOptions = {
            code: intialLang.code,
            nativeName: intialLang.nativeName,
            urlPrefix: intialLang.shortName,
            flag: countryCode,
            shortName: intialLang.shortName,
            aliases: intialLang.aliases,
        };
        // urlPrefix: "" означает что если соотв. язык выбран для показа
        // (например т.к. он есть в языках браузера) то в адресе не будет ничего (иначе было бы /en)
        if (language.code === "en-GB" || language.code === "en-PE") {
            language.urlPrefix = "";
        }
        return language;
    });
}

const allLanguages = [
    {
        code: "en-GB",
        nativeName: "English",
        // это короткое название языка, и насчет этого в нашей компании сложилось специальное правило
        //  если это не "основная страна языка" то показываем страну,
        // в противном случае язык"
        // Например, для Бразилии мы покажем страну BR, я не язык PT
        // Примерно так...
        shortName: "en",
        // aliases редиректятся на urlPrefix в "@webx-react/i18n"
        aliases: ["en"],
    },
    {
        code: "ru-RU",
        nativeName: "русский",
        shortName: "ru",
    },
    {
        code: "az-AZ",
        nativeName: "آذربايجان تورکجه سى",
        shortName: "az",
    },
    {
        code: "ar-AE",
        nativeName: "العربية",
        shortName: "ae",
    },
    {
        code: "bg-BG",
        nativeName: "български",
        shortName: "bg",
    },
    {
        code: "pt-BR",
        nativeName: "português",
        shortName: "br",
    },
    {
        code: "zh-CN",
        nativeName: "中文",
        shortName: "cn",
    },
    {
        code: "cs-CZ",
        nativeName: "čeština",
        shortName: "cz",
    },
    {
        code: "de-DE",
        nativeName: "Deutsch",
        shortName: "de",
    },
    {
        code: "da-DK",
        nativeName: "dansk",
        shortName: "dk",
    },
    {
        code: "es-ES",
        nativeName: "español",
        shortName: "es",
    },
    {
        code: "et-EE",
        nativeName: "eesti",
        shortName: "et",
    },
    {
        code: "fi-FI",
        nativeName: "suomi",
        shortName: "fi",
    },
    {
        code: "fr-FR",
        nativeName: "français",
        shortName: "fr",
    },
    {
        code: "el-GR",
        nativeName: "Ελληνικά",
        shortName: "gr",
    },
    {
        code: "he-IL",
        nativeName: "עברית",
        shortName: "he",
    },
    {
        code: "hi-IN",
        nativeName: "हिंदी",
        shortName: "hi",
    },
    {
        code: "hr-HR",
        nativeName: "hrvatski",
        shortName: "hr",
    },
    {
        code: "hu-HU",
        nativeName: "magyar",
        shortName: "hu",
    },
    {
        code: "id-ID",
        nativeName: "Indonesia",
        shortName: "id",
    },
    {
        code: "fa-IR",
        nativeName: "فارسى",
        shortName: "ir",
    },
    {
        code: "it-IT",
        nativeName: "italiano",
        shortName: "it",
    },
    {
        code: "ja-JP",
        nativeName: "日本語",
        shortName: "jp",
    },
    {
        code: "ko-KR",
        nativeName: "한국어",
        shortName: "kr",
    },
    {
        code: "lt-LT",
        nativeName: "lietuvių",
        shortName: "lt",
    },
    {
        code: "lv-LV",
        nativeName: "latviešu",
        shortName: "lv",
    },
    {
        code: "mk-MK",
        nativeName: "македонски",
        shortName: "mk",
    },
    {
        code: "mn-MN",
        nativeName: "монгол",
        shortName: "mn",
    },
    {
        code: "ms-MY",
        nativeName: "Bahasa Melayu",
        shortName: "ms",
    },
    {
        code: "nb-NO",
        nativeName: "norsk bokmål",
        shortName: "nb",
    },
    {
        code: "nl-NL",
        nativeName: "Nederlands",
        shortName: "nl",
    },
    {
        code: "pl-PL",
        nativeName: "polski",
        shortName: "pl",
    },
    {
        code: "pt-PT",
        nativeName: "português",
        shortName: "pt",
    },
    {
        code: "ro-RO",
        nativeName: "română",
        shortName: "ro",
    },
    {
        code: "sv-SE",
        nativeName: "svenska",
        shortName: "se",
    },
    {
        code: "sk-SK",
        nativeName: "slovenčina",
        shortName: "sk",
    },
    {
        code: "sr-RS",
        nativeName: "српски",
        shortName: "rs",
    },
    {
        code: "th-TH",
        nativeName: "ไทย",
        shortName: "th",
    },

    {
        code: "tr-TR",
        nativeName: "Türkçe",
        shortName: "tr",
    },
    {
        code: "zh-TW",
        nativeName: "中文",
        shortName: "tw",
    },
    {
        code: "uk-UA",
        nativeName: "українська",
        shortName: "ua",
    },
    {
        code: "uz-UZ",
        nativeName: "o‘zbek",
        shortName: "uz",
    },
    {
        code: "vi-VN",
        nativeName: "Tiếng Việt",
        shortName: "vi",
    },
    {
        code: "bn-BD",
        nativeName: "বাংলা",
        shortName: "bd",
    },
    {
        code: "km-KH",
        nativeName: "ភាសាខ្មែរ",
        shortName: "km",
    },
    {
        code: "kk-KZ",
        nativeName: "Қазақ тілі",
        shortName: "kz",
    },
    {
        code: "my-MM",
        nativeName: "မြန်မာဘာသာ",
        shortName: "mm",
    },
    {
        code: "ur-PK",
        nativeName: "မြاردو",
        shortName: "ur",
    },
    {
        code: "tl-PH",
        nativeName: "Tagalog",
        shortName: "ph",
    },
    {
        code: "so-SO",
        nativeName: "Soomaaliya",
        shortName: "so",
    },
    {
        code: "tg-TJ",
        nativeName: "забо́ни тоҷикӣ",
        shortName: "tg",
    },
    {
        code: "ky-KG",
        nativeName: "кыргызча",
        shortName: "ky",
    },
];

const languagesExtended = [
    ...allLanguages,
    { code: "sr-SP", nativeName: "Srpski jezik", shortName: "sr" },
    {
        code: "en-PE",
        nativeName: "English Peruvian",
        shortName: "en",
    },
    {
        code: "es-PE",
        nativeName: "Español Peruano",
        shortName: "es",
    },
];

// первый по списку язык, будет показываться если не удалось определить язык другими способами
export const LANGUAGE_LIST = new LanguageList().add(
    ...convertLanguageList(languagesExtended),
);

export function languageListByPartner(id?: string) {
    const languagesForFilter = new Map([
        [_22BET_PARTNERS, ["en-GB", "fr-FR", "zh-CN"]],
        [PARTNERS_22BET, ["en-GB", "fr-FR"]],
        [
            BIZBET_PARTNERS,
            [
                "ru-RU",
                "en-GB",
                "tr-TR",
                "pt-PT",
                "es-ES",
                "uz-UZ",
                "ar-AE",
                "bn-BD",
                "fr-FR",
                "mn-MN",
            ],
        ],
        [ONJABET_PARTNERS, ["en-GB", "fa-IR"]],
        [AFROPARI_PARTNERS, ["ru-RU", "en-GB", "fr-FR", "so-SO", "ar-AE"]],
        [_1XBET_PARTNERS_IRELAND, ["en-GB"]],
        [
            PARIPULSE_PARTNERS,
            ["en-GB", "fr-FR", "bn-BD", "ar-AE", "ur-PK", "hi-IN"],
        ],
        [
            PARIPULSE_PARTNERS_NG,
            ["en-GB", "fr-FR", "bn-BD", "ar-AE", "ur-PK", "hi-IN"],
        ],
        [
            VIVATBET_PARTNERS,
            [
                "en-GB",
                "es-ES",
                "et-EE",
                "fi-FI",
                "lt-LT",
                "da-DK",
                "it-IT",
                "fr-FR",
                "sv-SE",
                "nb-NO",
            ],
        ],
        [
            GOLDPARI_PARTNERS,
            ["en-GB", "fr-FR", "ar-AE", "hi-IN", "tr-TR", "es-ES", "pt-PT"],
        ],
        [PORTEBET_PARTNERS, ["ru-RU", "en-GB", "fr-FR", "ar-AE"]],
        [_1XPARTNERS_SPAIN, ["en-GB", "es-ES"]],
        [
            FAIRPARI_PARTNERS,
            [
                "ru-RU",
                "en-GB",
                "fr-FR",
                "pt-PT",
                "ro-RO",
                "hu-HU",
                "hr-HR",
                "de-DE",
                "it-IT",
                "pl-PL",
                "el-GR",
                "fi-FI",
                "ko-KR",
                "es-ES",
                "uz-UZ",
            ],
        ],
        [_1XBET_PARTNERS_SERBIA, ["en-GB", "sr-SP"]],
        [LILBET_PARTNERS, ["en-GB", "ru-RU", "kk-KZ", "uz-UZ"]],
        [PARTNERS_VIVATBET_RS, ["en-GB", "sr-SP"]],
        [VIVATBET_PARTNER, ["en-GB", "fr-FR"]],
        [PARTNERS_1XBET_PE, ["en-PE", "es-PE"]],
        [_1XBET_PARTNERS_BRAZIL, ["en-GB", "pt-BR"]],
    ]);
    if (id && languagesForFilter.has(id)) {
        const partnerLanguages = languagesForFilter.get(id);
        return new LanguageList().add(
            ...convertLanguageList(
                languagesExtended.filter(language =>
                    partnerLanguages?.includes(language.code),
                ),
            ),
        );
    }
    return new LanguageList().add(...convertLanguageList([...allLanguages]));
}
